import React from 'react';
import  Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import Logo from '../Logo@0.25x.png'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Deployments, Home, Relays } from './Pages';
import "../Styles/pages.css"

export default function TopNav(props){
    return(
      <BrowserRouter>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark"> 
          <Navbar.Brand>
            <img
              alt=""
              src= {Logo}
              width="30"
              height="30"
              style={{marginLeft:20,marginRight:10}}
              className="d-inline-block align-top"
            />{'  '}
            BESI-C Remote Monitoring
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" defaultActiveKey={'/'} activeKey={window.location.pathname}>
            <Nav.Link href="/">Overview</Nav.Link>
            <Nav.Link href="/Deployments">Deployments</Nav.Link>
            <Nav.Link href="/Relays">Relays</Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Navbar> 
        
        <br/>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="/Deployments">
            <Deployments/>
          </Route>
          <Route path="/Relays">
            <Relays/>
          </Route>
        </Switch>
      </BrowserRouter>
    )
}