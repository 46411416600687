import React from "react";
import "../Styles/spinner.css"

export default function Spinner(){
    return(
    <div className="LoaderContainer">    
        <div className="loader">Loading...</div>
    </div>

    )
}