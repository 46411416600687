import React from 'react';
import  TopNav from './components/TopNav'

function App() {

  return (
    <div className="App">
      <TopNav></TopNav>
    </div>
  );
}

export default App;
