import React from "react";
import { Table } from "react-bootstrap";
import { getAgoString } from "../utilities";
import "../Styles/table.css"
export function RelayTable(props){
    return(
        <div className="tableContainer">
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>LUX</th>
                    <th>Humidity</th>
                    <th>Temp</th>
                    <th>Pressure</th>
                    <th>Last Updated</th>
                </tr>
            </thead>
            <tbody>
                {props.relays.map((element, index)=> {
                    console.log({...element})
                    return (<Relay {...element}></Relay>)
                })}
            </tbody>
        </table>
        </div>
    )
}

export function Relay(props){
    return(
        <tr>{console.log(`Relay:${props}`)}
            <td>{`${props.DeviceID}`}</td>
            <td>{`${parseFloat(props.LUX).toFixed(2)}`}</td>
            <td>{`${parseFloat(props.HUM).toFixed(2)}%`}</td>
            <td>{`${parseFloat(props.TMP).toFixed(2)}°C`}</td>
            <td>{`${parseFloat(props.PRS).toFixed(2)}hPa`}</td>
            <td>{`${getAgoString(props.lastupdated)}`}</td>
        </tr>
    )
}