import React, { useEffect, useState } from 'react';
import { fetchData } from '../../utilities';
import DeploymentInfo from '../DeploymentInfo';
import Spinner from '../Spinner';


export default function Home(){
    const [loaded, setloaded] = useState(false)
    const [data, setData] = useState(null)

    const updateData = async ()=>{
        let serverData = await fetchData()
        console.log(serverData.data)
        setData(serverData.data)
        setloaded(true)
    }

    useEffect(() => {
        setInterval(updateData,1000)
        return () => {
        }
    }, [])
    return(
        <div className="pageContainer">
            <h2>Home</h2>
            <hr/>
            {loaded ? <DeploymentInfo deployments={data?.deployments}></DeploymentInfo>:<Spinner/>}
        </div>
    )
}