const durations = [
    [1000,60,"seconds",],
    [60*1000,60,"minutes"],
    [60*60*1000,24,"hours"],
    [24*60*60*1000,7,"days"],
    [7*24*60*60*1000,4,"weeks"],
    [4*7*24*60*60*1000,12,"months"],
    [12*4*7*24*60*60*1000,1000,"years"]
]

export function getAgoString(v){
    v = Date.now() - v
    let str = ''
    for(var i=0; i<7; i++){
        let val = durations[i]
        if(Math.ceil(v/val[0])<val[1]){
            str = `${Math.floor(v/val[0])} ${val[2]} ago`
            break
        }
    }
    return str
}

export async function fetchData(){
    try {
        let ret = await fetch("http://remote.besic.org/status")
        let r = await ret.json()
        return {data:r,error:null};
    } catch (error) {
        return {data:null,error};
    }
}