import React from "react";
import { RelayTable } from "./Relay";
import { getAgoString } from "../utilities";

export default function DeploymentInfo(props){
    return(
        <div>
            {Object.values(props.deployments ?? {}).map((deployment, _) =>{
                return (
                <div>    
                    <h3>{deployment.DeploymentID}</h3>
                    <p><b>BaseStation ID:</b> {deployment.DeviceID} | Last updated: {getAgoString(deployment.lastupdated)}</p>
                    {deployment.relays && <RelayTable relays={Object.values(deployment?.relays ?? {})}></RelayTable>}
                <br/>
                </div>
                )
            }) ?? {}}
        </div>
    )
}
